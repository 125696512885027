import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { Image } from "../components/Image"

const E10Image = number => `E10-0${number}.png`

const E10Page = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Hero>
        E10th St
        <Image src={E10Image(2)} />
      </Hero>

      <TextColumns offset>
        <div></div>
        <div>Steve Zagorski Architects</div>
        <div></div>
      </TextColumns>

      {[...Array(7)].map((image, index) => (
        <Image
          key={`E10th-${index}`}
          style={{ margin: "80px 0px" }}
          src={E10Image(index + 1)}
        />
      ))}
    </Layout>
  )
}

export default E10Page
